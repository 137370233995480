var request = function(method, url, params) {
  return $.ajax({
    url: url,
    dataType: 'json',
    method: method,
    processData: false,
    contentType: 'application/json',
    data: JSON.stringify({ kit: humps.decamelizeKeys(_.omit(params, 'id')) }),
  });
};

const KitConfigurationApi = {
  updateOrCreate: function(params) {
    if (params.id) return this.update(params);
    else return this.create(params);
  },

  update: function(params) {
    return request('PATCH', '/kits/' + params.id, params);
  },

  create: function(params) {
    return request('POST', '/kits', params);
  },
};

export default KitConfigurationApi;
