import React from "react";
import PropTypes from "prop-types";

import CheckboxItem from "./CheckboxItem";

export default class CheckboxList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selections: this.props.selections
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const target = e.target;

    this.setState(
      function(previousState) {
        const selections = _.clone(previousState.selections);

        if (target.checked) {
          selections.push(target.value);
        } else {
          _.pull(selections, target.value);
        }

        return { selections };
      },
      function() {
        this.props.onChange(this.state.selections);
      }
    );
  }

  renderOptions() {
    const options = this.props.options;

    return options.map((varName, index) => {
      return (
        <CheckboxItem
          checked={_.includes(this.state.selections, varName)}
          key={index}
          onChange={this.handleChange}
          value={varName}
        />
      );
    });
  }

  render() {
    return (
      <ul className="checkbox_list">
        {this.renderOptions()}
      </ul>
    );
  }
}

CheckboxList.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selections: PropTypes.array.isRequired,
}

CheckboxList.defaultProps = {
  onChange: () => {}
}
