// var React = require("react");
import React from 'react';

export default class ContentItemsIndex extends React.Component {
  constructor(props) {
    super(props);
    const indexData = humps.camelizeKeys(props);
    indexData.currentContentItems = indexData.contentItems.builtin;
    this.state = indexData;
    this.handleChangeRadioSelect = this.handleChangeRadioSelect.bind(this);
  }

  handleChangeRadioSelect(e) {
    const { name } = e.target;

    this.setState(function(previousState) {
      return {
        custom: name === 'custom',
        currentContentItems: this.state.contentItems[name],
      };
    });
  }

  _renderContentItemsTable() {
    if (!this.state.currentContentItems.length) {
      return (
        <div className="empty_message copy">
          <h4>You haven't created any custom content items yet.</h4>
        </div>
      );
    } else {
      return (
        <table className="standard clickable sortable">
          <thead>
            <tr>
              <th>Name</th>
              <th>Versions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.currentContentItems.map(function(contentItem, index) {
              var contentItemUrl = `/content_items/${contentItem.name}/edit`;

              return (
                <tr key={index}>
                  <td>
                    <a href={contentItemUrl}>
                      {contentItem.name}
                    </a>
                  </td>
                  <td>
                    <a href={contentItemUrl}>
                      {`${contentItem.versions}/${contentItem.possibleVersions}`}
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
  }

  render() {
    return (
      <div>
        <div className="split_button">
          <input
            checked={!this.state.custom}
            id="radio_0"
            name="builtin"
            type="radio"
            onChange={this.handleChangeRadioSelect}
          />
          <label id="built_in_label" htmlFor="radio_0">
            Built-in
          </label>
          <input
            checked={this.state.custom}
            id="radio_1"
            name="custom"
            type="radio"
            onChange={this.handleChangeRadioSelect}
          />
          <label id="custom_label" htmlFor="radio_1">
            Custom
          </label>
        </div>
        {this._renderContentItemsTable()}
      </div>
    );
  }
};
