function* cartesian([head = [], ...tail]) {
  const remaining = tail.length > 0 ? cartesian([...tail]) : [[]];
  for (let r of remaining) for (let h of head) yield [h, ...r];
}

function* take(list, count) {
  let i = 0;

  while (i < count) {
    let cur = list.next();
    if (cur.done) break;
    yield cur.value;
    i++;
  }
}

function* select(list, fn) {
  for (let item of list) if (fn(item)) yield item;
}

function* map(list, fn) {
  for (let item of list) yield fn(item);
}

export default function generateRows({
  activeVariables,
  allVariables,
  collapseBlanks,
  filter,
  limit,
  searchAndReplaceCopy: { query, replacement },
  fetchAndUpdateVersion,
  getVersion,
}) {
  const variableOptions = activeVariables.reduce((acc, newVariableName) => {
    acc.push(allVariables[newVariableName]);
    return acc;
  }, []);

  const transformVariation = values => {
    const valuesHash = values
      .slice(0)
      .reverse()
      .reduce(
        (acc, value, i) => ({ ...acc, [activeVariables[i]]: value }),
        {}
      );

    let version = getVersion(valuesHash) || {};
    let disabled = typeof version.content === 'undefined' || version.destroy;

    if (filterVariation({ values: valuesHash, enabled: !disabled })) {
      version = fetchAndUpdateVersion(valuesHash, query, replacement) || {};
      disabled = disabled || !versionContainsQuery(version);
    };

    return {
      enabled: !disabled,
      values: valuesHash,
      version: version.content,
    };
  };

  const versionContainsQuery = (version) => {
    return (
      query === '' ||
        textContainsQuery(version.content) ||
        textContainsQuery(version.originalContent)
    )
  }

  const textContainsQuery = (text) => {
    return text && text.toLowerCase().indexOf(query.toLowerCase()) >= 0
  }

  const filterVariation = ({ values, enabled }) => {
    const isNotFiltered = Object.keys(values).every(
      k => filter[k].indexOf(values[k]) >= 0
    );
    const isNotCollapsed = (collapseBlanks && enabled) || !collapseBlanks;
    return isNotFiltered && isNotCollapsed;
  };

  return [
    ...take(
      select(
        map(
          cartesian(variableOptions.slice(0).reverse()),
          transformVariation
        ),
        filterVariation
      ),
      limit
    ),
  ];
}
