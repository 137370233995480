import React from "react";
import PropTypes from "prop-types";

import ContentItemVariableTh from "./ContentItemVariableTh";

export default class ContentItemTableHead extends React.Component {
  renderThs() {
    const { activeVariables, allVariables, filter } = this.props;

    return _.compact(activeVariables).map((variableName, index) => {
      const onChange = this.props.onFilterChange(variableName);
      const options = allVariables[variableName];
      const selections = filter[variableName] || [];

      return (
        <ContentItemVariableTh
          key={variableName}
          {...{ onChange, options, selections, variableName }}
        />
      );
    });
  }

  render() {
    return (
      <table className="contentitems head">
        <thead>
          <tr>
            <th className="blank" />
            {this.renderThs()}
            <th>Text</th>
          </tr>
        </thead>
      </table>
    );
  }
}

ContentItemTableHead.propTypes = {
  activeVariables: PropTypes.array,
  allVariables: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};
