import React from "react";
import PropTypes from "prop-types";

export default class ContentItemSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.props.onChange({ value: e.target.value });
  }

  render() {
    return (
      <dl>
        <dt>
          <label>Search Copy</label>
        </dt>
        <dd>
          <textarea
            id="content_item_search"
            name="content_item_search"
            onChange={this.onChange}
            placeholder="Search for copy matches ..."
          />
        </dd>
      </dl>
    );
  }
}

ContentItemSearchInput.propTypes = {
  onChange: PropTypes.func.isRequired
}
