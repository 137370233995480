import React from "react";
import PropTypes from "prop-types";

import GenericSelect from "../../shared/GenericSelect";

export default class SelectPairItem extends React.Component {
  render() {
    return (
      <li>
        <a className="remove" href="#" onClick={this.props.onRemove}>remove</a>
        <GenericSelect className="kit_select_source" options={this.props.sourceOptions} value={this.props.sourceValue} onChange={this.props.onChangeSource} />
        <span className="arrow"></span>
        {this.props.children}
      </li>
    );
  }
}

SelectPairItem.propTypes = {
  sourceValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  sourceOptions: PropTypes.array,
  onChangeSource: PropTypes.func,
  onRemove: PropTypes.func
}

SelectPairItem.defaultProps =  {
  sourceOptions: [],
  onChangeSource: function() {},
  onRemove: function() {}
}
