import React from "react";
import PropTypes from "prop-types";

import GenericSelect from "../../shared/GenericSelect";

export default class ContentItemVariableSelects extends React.Component {
  _getActiveVariable(index) {
    return this.props.activeVariables[index] || '';
  }

  getAvailableVariables(currentVariableName) {
    const { activeVariables, allVariables } = this.props;

    const activeVariableNames = _.difference(activeVariables, [
      currentVariableName,
    ]);

    const availableVariables = _(Object.keys(allVariables))
      .difference(activeVariableNames)
      .value();

    return availableVariables;
  }

  renderVariableSelects() {
    const selectComponents = [];

    for (let index = 0; index < 9; index++) {
      const onChange = this.props.onChange.bind(null, index);
      const value = this._getActiveVariable(index);
      const options = this.getAvailableVariables(value);

      selectComponents.push(
        <div key={index} className="col_4" style={{ marginBottom: '15px' }}>
          <GenericSelect
            name={'select' + index}
            onChange={onChange}
            options={options}
            value={value}
            withBlank
          />
        </div>
      );
    }

    return selectComponents;
  }

  render() {
    return (
      <dl>
        <dt>
          <div className="flex-row flex-row-center">
            <label className="flex-row-item">Variables</label>
            <div className="tooltip flex-row-item nudge-right" title="Users have to know how many variations there are of the content item being edited. Users can select the variables that tie to the different variations."></div>
          </div>
        </dt>
        <dd>
          <div className="row">
            {this.renderVariableSelects()}
          </div>
        </dd>
      </dl>
    );
  }
}

ContentItemVariableSelects.propTypes = {
  activeVariables: PropTypes.array,
  onChange: PropTypes.func,
}

ContentItemVariableSelects.defaultProps = {
  activeVariables: [],
  onChange() {}
}
