import React from "react";

import KitConfigurationApi from "./KitConfigurationApi"

import GenericStringInput from "../shared/GenericStringInput";
import KitSelect from "./components/KitSelect";
import KitConstantsSelectList from "./components/KitConstantsSelectList";
import VariablesForExportCheckboxList from "./components/VariablesForExportCheckboxList";
import ContentItemOverridesSelectList from "./components/ContentItemOverridesSelectList";
import TextOverridesList from "./components/TextOverridesList";

export default class KitConfigure extends React.Component {
  constructor(props) {
    super(props);

    this.state = window.KIT_CONFIGURATION;

    this.getAvailableKitConstants = this.getAvailableKitConstants.bind(this);
    this.getAvailableContentItems = this.getAvailableContentItems.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeKitConstantSource = this.handleChangeKitConstantSource.bind(this);
    this.handleChangeKitConstantTarget = this.handleChangeKitConstantTarget.bind(this);
    this.handleRemoveKitConstant = this.handleRemoveKitConstant.bind(this);
    this.handleAddKitConstant = this.handleAddKitConstant.bind(this);
    this.handleVariablesForExportChange = this.handleVariablesForExportChange.bind(this);
    this.handleChangeContentItemOverride = this.handleChangeContentItemOverride.bind(this);
    this.handleRemoveContentItemOverride = this.handleRemoveContentItemOverride.bind(this);
    this.handleAddContentItemOverride = this.handleAddContentItemOverride.bind(this);
    this.handleChangeTextOverrideSelect = this.handleChangeTextOverrideSelect.bind(this);
    this.handleChangeTextOverrideText = this.handleChangeTextOverrideText.bind(this);
    this.handleAddTextOverride = this.handleAddTextOverride.bind(this);
    this.handleRemoveTextOverride = this.handleRemoveTextOverride.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this._updateKitConfigurationFail = this._updateKitConfigurationFail.bind(this);
  }

  getAvailableKitConstants(currentVariableName) {
    var activeVariableNames = _(this.state.activeKitConstants)
      .pluck('variableName')
      .difference([currentVariableName])
      .value();
    var constants = _(this.state.kitConstantOptions)
      .pluck('variableName')
      .difference(activeVariableNames)
      .value();
    return constants;
  }

  getAvailableContentItems(sourceName, stateKey) {
    var pluckKey = stateKey === 'contentItemOverrides' ? 'source' : 'name';
    var activeContentItems = _(this.state[stateKey])
      .pluck(pluckKey)
      .difference([sourceName])
      .value();
    var builtinItems = _(this.state.builtinContentItemOptions)
      .difference(activeContentItems)
      .value();
    return builtinItems;
  }

  handleInputChange(state) {
    this.setState(state);
  }

  handleChangeKitConstantSource(variableName, e) {
    var newVariableName = e.target.value;

    this.setState(function(previousState) {
      var activeKitConstants = _.cloneDeep(previousState.activeKitConstants);
      var kitConstant = _.find(activeKitConstants, {
        variableName: variableName,
      });
      kitConstant.variableName = newVariableName;
      kitConstant.valueNames = [
        _.find(previousState.kitConstantOptions, {
          variableName: newVariableName,
        }).defaultValue,
      ];

      return {
        activeKitConstants: activeKitConstants,
      };
    });
  }

  handleChangeKitConstantTarget(variableName, values) {
    this.setState(function(previousState) {
      var activeKitConstants = _.cloneDeep(previousState.activeKitConstants);
      var activeKitConstant = _.find(activeKitConstants, {
        variableName: variableName,
      });
      activeKitConstant.valueNames = values;

      return {
        activeKitConstants: activeKitConstants,
      };
    });
  }

  handleRemoveKitConstant(variableName, e) {
    e.preventDefault();

    this.setState(function(previousState) {
      var activeKitConstants = _.cloneDeep(previousState.activeKitConstants);
      var targetConstantIndex = _.findIndex(activeKitConstants, {
        variableName: variableName,
      });
      _(activeKitConstants).pullAt(targetConstantIndex).value();
      return {
        activeKitConstants: activeKitConstants,
      };
    });
  }

  handleAddKitConstant(e) {
    e.preventDefault();

    var variableName = _.first(this.getAvailableKitConstants());

    this.setState(function(previousState) {
      var activeKitConstants = previousState.activeKitConstants;
      var valueName = _.find(previousState.kitConstantOptions, {
        variableName: variableName,
      }).defaultValue;
      var newActiveKitConstants = _.union(activeKitConstants, [
        { variableName: variableName, valueNames: [valueName], id: null }
      ]);

      return {
        activeKitConstants: newActiveKitConstants,
      };
    });
  }

  handleVariablesForExportChange(values) {
    this.setState({ variablesForExport: values });
  }

  handleChangeContentItemOverride(sourceName, targetName, key, e) {
    const { value }  = e.target;

    this.setState(function(previousState) {
      var contentItemOverrides = _.cloneDeep(
        previousState.contentItemOverrides
      );
      var contentItemOverride = _.find(contentItemOverrides, {
        source: sourceName,
        target: targetName,
      });
      contentItemOverride[key] = value;

      return {
        contentItemOverrides: contentItemOverrides,
      };
    });
  }

  handleRemoveContentItemOverride(sourceName, targetName, e) {
    e.preventDefault();

    this.setState(function(previousState) {
      var contentItemOverrides = _.cloneDeep(
        previousState.contentItemOverrides
      );
      var contentItemOverrideIndex = _.findIndex(contentItemOverrides, {
        source: sourceName,
        target: targetName,
      });
      _.pullAt(contentItemOverrides, contentItemOverrideIndex);

      return {
        contentItemOverrides: contentItemOverrides,
      };
    });
  }

  handleAddContentItemOverride(e) {
    e.preventDefault();

    var contentItemSource = _.first(
      this.getAvailableContentItems(null, 'contentItemOverrides')
    );

    this.setState(function(previousState) {
      var contentItemOverrides = previousState.contentItemOverrides;
      var contentItemOverrideTarget = _.first(
        previousState.customContentItemOptions
      );
      var newContentItemOverrides = _.union(contentItemOverrides, [
        { source: contentItemSource, target: contentItemOverrideTarget }
      ]);

      return {
        contentItemOverrides: newContentItemOverrides,
      };
    });
  }

  handleChangeTextOverrideSelect(sourceName, e) {
    const { value } = e.target;

    this.setState(function(previousState) {
      var textOverrides = _.cloneDeep(previousState.textOverrides);
      var textOverride = _.find(textOverrides, { name: sourceName });
      textOverride.name = value;

      return {
        textOverrides: textOverrides,
      };
    });
  }

  handleChangeTextOverrideText(sourceName, e) {
    const { value } = e.target;

    this.setState(function(previousState) {
      var textOverrides = _.cloneDeep(previousState.textOverrides);
      var textOverride = _.find(textOverrides, { name: sourceName });
      textOverride.text = value;

      return {
        textOverrides: textOverrides,
      };
    });
  }

  handleAddTextOverride(e) {
    e.preventDefault();

    var contentItemSource = _.first(
      this.getAvailableContentItems(null, 'textOverrides')
    );

    this.setState(function(previousState) {
      var textOverrides = previousState.textOverrides;
      var newTextOverrides = _.union(textOverrides, [
        { name: contentItemSource, text: '' }
      ]);

      return {
        textOverrides: newTextOverrides,
      };
    });
  }

  handleRemoveTextOverride(sourceName, e) {
    e.preventDefault();

    this.setState(function(previousState) {
      var textOverrides = _.cloneDeep(previousState.textOverrides);
      var textOverrideIndex = _.findIndex(textOverrides, { name: sourceName });
      _.pullAt(textOverrides, textOverrideIndex);

      return {
        textOverrides: textOverrides,
      };
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    var params = _.pick(
      this.state,
      'activeKitConstants',
      'contentItemOverrides',
      'externalId',
      'id',
      'kitCampaign',
      'kitEthnicity',
      'kitSize',
      'kitTestIdentifier',
      'name',
      'textOverrides',
      'variablesForExport'
    );
    KitConfigurationApi.updateOrCreate(params)
      .done(this._updateKitConfigurationSuccess)
      .fail(this._updateKitConfigurationFail);
  }

  _updateKitConfigurationSuccess(data, status, xhr) {
    window.location.href = '/kits/' + data.id;
  }

  _updateKitConfigurationFail(xhr, status, err) {
    var newErrors = humps.camelizeKeys(xhr.responseJSON.errors);
    this.setState({ errors: newErrors });
  }

  render() {
    var errors = this.state.errors;

    return (
      <div id="kit_edit">
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col_7">
              <GenericStringInput
                defaultValue={this.state.name}
                labelName="Kit Description"
                errorMessage={errors.name}
                onChange={this.handleInputChange}
                stateKey="name"
                placeholder="Enter Kit Description ..."
              />
            </div>
            <div className="col_5">
              <GenericStringInput
                defaultValue={this.state.externalId}
                labelName="Book ID"
                size="small"
                stateKey="externalId"
                errorMessage={errors.externalId}
                onChange={this.handleInputChange}
                placeholder="Book ID ..."
                inline
              />
              <KitSelect
                defaultValue={this.state.kitSize}
                inline
                labelName="Kit Size"
                onChange={this.handleInputChange}
                options={this.state.kitSizeOptions}
                stateKey="kitSize"
              />
            </div>
          </div>
          <div className="row imax_attributes">
            <div className="col_3">
              <GenericStringInput
                defaultValue={this.state.kitCampaign}
                labelName="Campaign"
                onChange={this.handleInputChange}
                stateKey="kitCampaign"
                placeholder="Enter Campaign..."
              />
            </div>
            <div className="col_3">
              <GenericStringInput
                defaultValue={this.state.kitEthnicity}
                labelName="Ethnicity"
                onChange={this.handleInputChange}
                stateKey="kitEthnicity"
                placeholder="Enter Ethnicity..."
              />
            </div>
            <div className="col_3">
              <GenericStringInput
                defaultValue={this.state.kitTestIdentifier}
                labelName="Test Identifier"
                onChange={this.handleInputChange}
                stateKey="kitTestIdentifier"
                placeholder="Enter Test Identifier..."
              />
            </div>
          </div>
          <div className="row">
            <div className="col_7">
              <KitConstantsSelectList
                activeKitConstants={this.state.activeKitConstants}
                errorMessage={errors.kitConstants}
                getSourceConstants={this.getAvailableKitConstants}
                kitConstantOptions={this.state.kitConstantOptions}
                onAdd={this.handleAddKitConstant}
                onChangeSource={this.handleChangeKitConstantSource}
                onChangeTarget={this.handleChangeKitConstantTarget}
                onRemove={this.handleRemoveKitConstant}
              />
            </div>
            <div className="col_5">
              <VariablesForExportCheckboxList
                options={this.state.variablesForExportOptions}
                selections={this.state.variablesForExport}
                onChange={this.handleVariablesForExportChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col_12">
              <ContentItemOverridesSelectList
                overrides={this.state.contentItemOverrides}
                builtinOptions={this.state.builtinContentItemOptions}
                customOptions={this.state.customContentItemOptions}
                onChangeSource={this.handleChangeContentItemOverride}
                onChangeTarget={this.handleChangeContentItemOverride}
                getSourceContentItems={this.getAvailableContentItems}
                onRemove={this.handleRemoveContentItemOverride}
                onAdd={this.handleAddContentItemOverride}
              />
              <TextOverridesList
                overrides={this.state.textOverrides}
                options={this.state.builtinContentItemOptions}
                onChangeSelect={this.handleChangeTextOverrideSelect}
                onChangeText={this.handleChangeTextOverrideText}
                getSourceContentItems={this.getAvailableContentItems}
                onAdd={this.handleAddTextOverride}
                onRemove={this.handleRemoveTextOverride}
              />
            </div>
          </div>
          <fieldset className="actions">
            <button
              type="submit"
              onClick={function() {
                window.onbeforeunload = null;
              }}
            >
              Save Changes
            </button>
            <a href="javascript:history.back()">Back</a>
          </fieldset>
        </form>
      </div>
    );
  }
}
