import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ContentEditable from "../../shared/ContentEditable";

export default class VariationRow extends React.Component {
  constructor(props) {
    super(props);

    ['onCheckboxChange', 'onVersionChange'].forEach(bound => {
      this[bound] = this[bound].bind(this);
    });
    this.state = {
      version: props.version,
      enabled: props.enabled,
    };
  }

  componentWillReceiveProps(newProps) {
    if (this.props.version !== newProps.version)
      this.setState({ version: newProps.version });
  }

  onCheckboxChange() {
    const enabled = !this.state.enabled;
    this.props.toggleVersion(enabled);
    this.setState({ enabled });
  }

  onVersionChange({ target: { value } }) {
    this.props.setVersion(value);
    this.setState({ version: value });
  }

  getClassName() {
    return classNames({
      disabled: !this.isEnabled(),
    });
  }

  countCharactersNoEntities(value) {
    return value ? value.replace(/&[^\s]*;/g, '').length : null;
  }

  isEnabled() {
    return this.state.enabled;
  }

  renderValueCells() {
    const { variation } = this.props;

    return Object.keys(variation).map((variableName, index) => {
      const variableOption = variation[variableName];

      return (
        <td className="variable_cell" key={`${index}-${variableOption}`}>
          {variableOption}
        </td>
      );
    });
  }

  render() {
    const { version } = this.state;
    const characterCount = this.countCharactersNoEntities(version);

    return (
      <tr className={this.getClassName()}>
        <td className="checkbox_cell">
          <input
            checked={this.isEnabled()}
            className="switch"
            onChange={this.onCheckboxChange}
            type="checkbox"
          />
        </td>

        {this.renderValueCells()}

        <td className="editable_cell">
          <span className="character_count">
            {characterCount}
          </span>

          <ContentEditable
            className="editable_area"
            disabled={!this.isEnabled()}
            html={version}
            onChange={this.onVersionChange}
          />
        </td>
      </tr>
    );
  }
}

VariationRow.propTypes = {
  variation: PropTypes.object.isRequired,
  version: PropTypes.string,
  enabled: PropTypes.bool.isRequired,
  setVersion: PropTypes.func.isRequired,
  toggleVersion: PropTypes.func.isRequired,
};
