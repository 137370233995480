import React from "react";
import ReactDOM from "react-dom";
// http://stackoverflow.com/questions/22677931/react-js-onchange-event-for-contenteditable/27255103#27255103

export default class ContentEditable extends React.Component {
  constructor(props) {
    super(props);

    this.emitChange = this.emitChange.bind(this);
  }
  componentDidUpdate() {
    if (this.props.html !== ReactDOM.findDOMNode(this).innerHTML) {
      ReactDOM.findDOMNode(this).innerHTML = this.props.html;
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.html !== ReactDOM.findDOMNode(this).innerHTML;
  }

  emitChange() {
    var html = ReactDOM.findDOMNode(this).value;

    if (this.props.onChange && html !== this.lastHtml) {
      this.props.onChange({
        target: {
          value: html,
        },
      });
    }

    this.lastHtml = html;
  }

  render() {
    return (
      <textarea
        className="editable_area"
        value={this.props.html}
        onBlur={this.emitChange}
        onChange={this.emitChange}
        style={{height: this.props.html.length / 56 * 21 + 32 + 'px'}} //length / chars-per-line * line-height + padding
      />
    );
  }
}

ContentEditable.defaultProps = {
  html: ''
}
