import React from "react";
import PropTypes from "prop-types";

// seems like this should move to "shared"
import CheckboxList from "../../KitConfigure/components/CheckboxList";

export default class ContentItemVariableTh extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownDropped: false,
    };

    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  getClasses() {
    if (this.state.dropdownDropped) {
      return 'dropdown-dropped';
    }

    return null;
  }

  toggleDropdown(domEvent) {
    domEvent.preventDefault();

    this.setState({ dropdownDropped: !this.state.dropdownDropped });
  }

  renderIcon() {
    let icon = '▼';

    if (this.state.dropdownDropped) {
      icon = '▲';
    }

    return <span className="icon">{icon}</span>;
  }

  renderOptions() {
    const { onChange, options, selections } = this.props;

    return (
      <div className="dropdown">
        <CheckboxList {...{ onChange, options, selections }} />
      </div>
    );
  }

  render() {
    const { variableName } = this.props;
    const label = variableName.replace(/_/g, ' ');

    return (
      <th className={this.getClasses()} key={variableName}>
        <a href="#" onClick={this.toggleDropdown}>
          <span className="label">{label}</span>
          {this.renderIcon()}
        </a>
        {this.renderOptions()}
      </th>
    );
  }
}

ContentItemVariableTh.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selections: PropTypes.array.isRequired,
  variableName: PropTypes.string.isRequired,
};

ContentItemVariableTh.defaultProps = {
  onChange() {},
};
