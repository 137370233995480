import React from "react";

import VersionHash from "../VersionHash";
import ContentItemApi from "../ContentItem/ContentItemApi";

import GenericStringInput from "../shared/GenericStringInput";
import DefaultContentInput from "./components/DefaultContentInput";
import ContentItemVariableSelects from "./components/ContentItemVariableSelects";
import ContentItemTable from "./components/ContentItemTable";
import CollapseButton from "./components/filters/CollapseButton";
import ContentItemSearchInput from "./components/ContentItemSearchInput";
import ContentItemReplaceCopyInput from "./components/ContentItemReplaceCopyInput";


const buildFilter = ({ activeVariables, variables }) =>
  activeVariables.reduce((accumulator, variableName) => {
    accumulator[variableName] = variables[variableName];
    return accumulator;
  }, {});

const LoadingIndicator = ({ status }) => {
  const className = {
    init: '',
    false: 'loaded',
    saving: 'loading',
    loading: 'loading',
  }[status];

  return (
    <div id="loading-indicator" {...{ className }}>
      <div className="activity_indicator" />
    </div>
  );
};

export default class ContentItemForm extends React.Component {
  constructor(props) {
    super(props);

    const {
      activeVariables,
      allVariables,
      defaultContent: default_content,
      errors,
      name,
    } = props;

    this.state = {
      activeVariables,
      collapseBlanks: true,
      contentItem: {
        default_content,
        name,
      },
      errors,
      filter: buildFilter({
        activeVariables: Object.keys(allVariables),
        variables: allVariables,
      }),
      loading: 'init',
      renderSearch: false,
      searchAndReplaceCopy: { query: '', replacement: '' },
    };

    this.versionHash = new VersionHash(props);

    [
      'onSubmit',
      'onSubmitFail',
      'onVariablesChange',
      'updateFilter',
      'toggleCollapsed',
      'toggleSearchInput',
      'onSearchQueryChange',
      'onReplaceCopyChange',
    ].forEach(bound => {
      this[bound] = this[bound].bind(this);
    });
  }

  onContentItemPropChange(prop) {
    return ({ value }) => {
      warnOnUnload();
      this.setState({
        contentItem: {
          ...this.state.contentItem,
          [prop]: value,
        },
      });
    };
  }

  onSubmit(e) {
    e.preventDefault();
    this.withLoadingIndicator('saving', done => {
      const formData = {
        data: JSON.stringify({
          content_item: {
            ...this.state.contentItem,
            ...this.versionHash.serialize(),
          },
        }),
        url: `/content_items/${this.props.name}`,
      };

      const submitAction = {
        edit: 'update',
        new: 'create',
      }[this.props.action];

      ContentItemApi[submitAction](formData)
        .then(this.onSubmitSuccess)
        .catch(this.onSubmitFail)
        .then(() => {
          done();
        });
    });
  }

  onSubmitFail(xhr, status, err) {
    let newErrors = humps.camelizeKeys(xhr.responseJSON.errors);
    flash.error('Failed to save');
    this.setState(previousState => {
      return { errors: newErrors };
    });
  }

  onSubmitSuccess({ redirect, notice }, status, xhr) {
    flash.notice(notice);
    warnOnUnload(false);
    if (redirect) window.location = redirect;
  }

  withLoadingIndicator(level, fn) {
    this.setState({ loading: level });
    requestAnimationFrame(() => {
      setTimeout(() => {
        const done = (newState = {}) => {
          this.setState({ loading: false, ...newState });
        };
        fn(done);
      }, 0);
    });
  }

  onVariablesChange(index, e) {
    warnOnUnload();
    const { value } = e.target;

    const getNewActiveVariables = () => {
      const newActiveVariables = [...this.state.activeVariables];

      const insertIndex =
        index > newActiveVariables.length ? newActiveVariables.length : index;

      if (value) {
        newActiveVariables[insertIndex] = value;
      } else {
        newActiveVariables.splice(insertIndex, 1);
      }

      return newActiveVariables;
    };

    this.withLoadingIndicator('loading', done => {
      const newActiveVariableNames = getNewActiveVariables();

      this.versionHash.setVariables(
        newActiveVariableNames.reduce((acc, variable) => {
          acc[variable] = this.props.allVariables[variable];
          return acc;
        }, {})
      );

      done({ activeVariables: newActiveVariableNames });
    });
  }

  onSearchQueryChange(e) {
    this.setState({
      searchAndReplaceCopy: { ...this.state.searchAndReplaceCopy, query: e.value }
    });
  }

  onReplaceCopyChange(e) {
    this.setState({
      searchAndReplaceCopy: { ...this.state.searchAndReplaceCopy, replacement: e.value }
    });
  }

  componentDidUpdate() {
    printBenchmarks();
  }

  toggleCollapsed(e) {
    e.preventDefault();
    this.setState({ collapseBlanks: !this.state.collapseBlanks });
  }

  updateFilter(variableName) {
    return selections => {
      this.withLoadingIndicator('loading', done => {
        const filterState = { [variableName]: selections };
        const filter = { ...this.state.filter, ...filterState };

        done({ filter });
      });
    };
  }

  renderCollapseButton() {
    const { activeVariables, collapseBlanks } = this.state;

    if (activeVariables.length > 0) {
      return (
        <CollapseButton
          collapseBlanks={collapseBlanks}
          onClick={this.toggleCollapsed}
        />
      );
    }

    return null;
  }

  toggleSearchInput(e) {
    e.preventDefault();
    this.setState({ renderSearch: !this.state.renderSearch });
  }

  renderReplaceCopyButton() {
    return (
      <a className="button" href="#" onClick={this.toggleSearchInput}>
        Search and Replace Copy
      </a>
    );
  }

  renderSearchAndReplaceInputs() {
    if (this.state.renderSearch) {
      return(
        <div className="search_and_replace">
          <ContentItemSearchInput
            onChange={this.onSearchQueryChange}
          />
          <ContentItemReplaceCopyInput
            onChange={this.onReplaceCopyChange}
          />
        </div>
      )
    }

    return null
  }

  renderSubmitButton() {
    const saving = this.state.loading === 'saving';

    let activityIndicator;
    let text = 'Save Changes';

    if (saving) {
      activityIndicator = <div className="activity_indicator" />;
      text = 'Saving';
    }

    return (
      <button
        className="button submit"
        disabled={saving}
        name="button"
        onClick={this.onSubmit}
        type="submit"
      >
        {activityIndicator}
        {text}
      </button>
    );
  }

  renderTitle() {
    const { action, name } = this.props;
    const { loading } = this.state;

    if (action === 'edit') {
      return (
        <header className="subhead margin-bottom-20">
          <h2>
            {name}
          </h2>
          <LoadingIndicator status={loading} />
        </header>
      );
    }

    return (
      <GenericStringInput
        errorMessage={this.state.errors.name}
        labelName="Name"
        onChange={this.onContentItemPropChange('name')}
        placeholder="Custom content item name ..."
        stateKey="value"
        value={name}
      />
    );
  }

  render() {
    const {
      activeVariables,
      collapseBlanks,
      errors,
      filter,
      loading,
      searchAndReplaceCopy,
    } = this.state;
    const { action, allVariables, defaultContent } = this.props;

    return (
      <div id={`contentitems_${action}`}>
        <form>
          {this.renderTitle()}

          <DefaultContentInput
            defaultValue={defaultContent}
            errorMessage={errors.defaultContent}
            inputId="content_item_default_content"
            onChange={this.onContentItemPropChange('default_content')}
          />

          <ContentItemVariableSelects
            activeVariables={activeVariables}
            allVariables={allVariables}
            onChange={this.onVariablesChange}
          />

          <div className="buttons">
            {this.renderCollapseButton()}
            {this.renderReplaceCopyButton()}
            {this.renderSubmitButton()}
          </div>

          {this.renderSearchAndReplaceInputs()}

          <ContentItemTable
            activeVariables={activeVariables}
            allVariables={allVariables}
            collapseBlanks={collapseBlanks}
            filter={filter}
            searchAndReplaceCopy={searchAndReplaceCopy}
            onFilterChange={this.updateFilter}
            versionHash={this.versionHash}
          />
        </form>
      </div>
    );
  }
}
