const ajax = params =>
  new Promise((resolve, reject) => {
    $.ajax(getAjaxParams(params)).done(resolve).fail(reject);
  });

function getAjaxParams(params) {
  const defaults = {
    contentType: 'application/json',
    dataType: 'json',
  };

  return _.extend(defaults, params);
}

const ContentItemApi = {
  create({ data }) {
    var ajaxParams = getAjaxParams({
      data,
      method: 'POST',
      url: '/content_items',
    });

    return ajax(ajaxParams);
  },

  update({ data, url }) {
    var ajaxParams = getAjaxParams({
      data,
      method: 'PATCH',
      url,
    });

    return ajax(ajaxParams);
  },
};

export default ContentItemApi;
