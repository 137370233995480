export default class VersionHash {
  constructor({ activeVariables, allVariables, variations }) {
    this.hash = {};
    this.deserialize({ activeVariables, allVariables, variations });

    this.getVersion = this.getVersion.bind(this);
    this.setVersion = this.setVersion.bind(this);
    this.fetchAndUpdateVersion = this.fetchAndUpdateVersion.bind(this);
  }

  getVersion(variation) {
    return this.hash[this.variationToKey(variation)];
  }

  fetchAndUpdateVersion(variation, query, replacement) {
    const version = this.getVersion(variation) || {};
    if (!version.content) return version;
    if ((query === '' || replacement === '') && !version.originalContent) return version;

    if ((query === '' || replacement === '') && version.originalContent) {
      this.setVersion({
        variation,
        content: version.originalContent,
        originalContent: null
      })
      return this.getVersion(variation);
    }

    let content = version.originalContent || version.content;

    if (content.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
      const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const queryRegex = new RegExp(escapedQuery, 'ig');

      this.setVersion({
        variation,
        content: content.replace(queryRegex, replacement),
        originalContent: content
      });
    }

    return this.getVersion(variation);
  }

  setVariables(variables) {
    const currentVariableNames = this.variableNames();
    const newVariableNames = Object.keys(variables).sort();

    if (newVariableNames.length > currentVariableNames.length) {
      this.hash = this.addVariable({
        currentVariableNames,
        newVariableNames,
        newVariables: variables,
      });
    } else if (newVariableNames.length < currentVariableNames.length) {
      this.hash = this.subtractVariable({
        currentVariableNames,
        newVariableNames,
      });
    } else if (
      newVariableNames.toString() !== currentVariableNames.toString()
    ) {
      this.hash = {};
    }

    this.activeVariables = newVariableNames;
  }

  setVersion({ content, destroy, variation, originalContent }) {
    this.hash[this.variationToKey(variation)] = {
      content,
      originalContent,
      destroy: !!destroy,
    };
  }

  toggleVersionDelete(variation, destroy) {
    const { content } = this.getVersion(variation) || { content: '' };
    this.setVersion({ content, destroy, variation });
  }

  addVariable({ currentVariableNames, newVariableNames, newVariables }) {
    const addedVariableNames = this.difference(
      newVariableNames,
      currentVariableNames
    );

    const newVariableVariations = addedVariableNames.reduce((acc, variable) => {
      newVariables[variable].forEach(value => {
        acc.push({ [variable]: value });
      });

      return acc;
    }, []);

    const currentVariations = this.variations();

    return currentVariations.reduce((acc, variation) => {
      const variationKey = this.variationToKey(variation);

      newVariableVariations.forEach(newVariation => {
        const newKey = this.variationToKey(
          Object.assign({}, variation, newVariation)
        );
        acc[newKey] = this.getVersion(variation);
      });

      return acc;
    }, {});
  }

  deserialize({ activeVariables, allVariables, variations }) {
    this.activeVariables = activeVariables.sort();

    variations.forEach(({ values, version }) => {
      if (version !== null) {
        const variation = activeVariables.reduce((acc, variable, i) => {
          acc[variable] = values[i];
          return acc;
        }, {});

        this.setVersion({
          content: version,
          destroy: false,
          variation,
        });
      }
    });
  }

  difference(a1, a2) {
    return a1.filter(item => a2.indexOf(item) === -1);
  }

  subtractVariable({ currentVariableNames, newVariableNames }) {
    const removedVariableNames = this.difference(
      currentVariableNames,
      newVariableNames
    );

    return Object.keys(this.hash).reduce((acc, key) => {
      const newKey = JSON.stringify(
        JSON.parse(key).filter(
          ([k, v]) => removedVariableNames.indexOf(k) === -1
        )
      );

      const version = this.hash[key];
      acc[newKey] = version;
      return acc;
    }, {});
  }

  serialize() {
    const variations = Object.keys(this.hash).map(key => {
      const { content: version, destroy } = this.hash[key];

      return {
        destroy,
        values: JSON.parse(key).map(([k, v]) => v),
        version,
      };
    });

    return {
      active_variables: this.activeVariables,
      variations,
    };
  }

  variableNames() {
    const firstKey = JSON.parse(Object.keys(this.hash)[0] || '[]');
    return firstKey.map(([k, v]) => k);
  }

  variations() {
    return Object.keys(this.hash).map(key => {
      return JSON.parse(key).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
    });
  }

  variationToKey(o) {
    const key = JSON.stringify(Object.keys(o).sort().map(key => [key, o[key]]));
    return key;
  }
}
