import React from "react";
import PropTypes from "prop-types";

import CheckboxList from "./CheckboxList";

export default class VariablesForExportCheckboxList extends React.Component {
  render() {
    return (
      <div id="variables_for_export">
        <div className="flex-row flex-row-center margin-bottom-20">
          <h3 className="subhead flex-row-item ">Variables for Export</h3>
          <div className="tooltip flex-row-item nudge-right" title="These are column headers in the CSV export. Therefore, they all need to be selected except ones we know we are not using in this specific kit."></div>
        </div>
        <CheckboxList
          onChange={this.props.onChange}
          options={this.props.options}
          selections={this.props.selections}
        />
      </div>
    );
  }
}

VariablesForExportCheckboxList.propTypes = {
  options: PropTypes.array,
  selections: PropTypes.array,
  onChange: PropTypes.func,
}

VariablesForExportCheckboxList.defaultProps = {
  options: [],
  selections: [],
  onChange: function() {},
}
