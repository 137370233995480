import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class DefaultContentInput extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  _getDefaultContentLabelText() {
    let labelText = 'Default Content';

    if (this.props.errorMessage) {
      labelText = labelText + ' ' + this.props.errorMessage;
    }

    return labelText;
  }

  _inputClassNames() {
    return classNames({
      autosize: true,
      'flex-row-item': true,
      error: !_.isEmpty(this.props.errorMessage),
    });
  }

  _labelClassNames() {
    return classNames({
      error: !_.isEmpty(this.props.errorMessage),
    });
  }

  onChange(e) {
    this.props.onChange({ value: e.target.value });
  }

  render() {
    const { defaultValue, inputId } = this.props;

    return (
      <dl>
        <dt className="flex-row flex-row-center">
          <label className={this._labelClassNames()} htmlFor={inputId}>
            {this._getDefaultContentLabelText()}
          </label>
          <div className="tooltip flex-row-item nudge-right" title="Default content will be used if there is a variable combination that is not accounted for below."></div>
        </dt>
        <dd>
          <textarea
            className={this._inputClassNames()}
            defaultValue={defaultValue}
            id={inputId}
            name={inputId}
            onChange={this.onChange}
            placeholder="Enter default content ..."
          />
        </dd>
      </dl>
    );
  }
}

DefaultContentInput.propTypes = {
  defaultValue: PropTypes.string,
  errorMessage: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

DefaultContentInput.defaultProps = {
  errorMessage: null
}
