import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class GenericStringInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  _parentClassNames() {
    return classNames({
      inline: this.props.inline,
    });
  }

  _constructLabelName() {
    var labelName = this.props.labelName;
    if (this.props.errorMessage) {
      labelName = labelName + ' ' + this.props.errorMessage;
    }
    return labelName;
  }

  _inputClassNames() {
    return classNames({
      short: this.props.size === 'small',
      error: !_.isEmpty(this.props.errorMessage),
    });
  }

  _labelClassNames() {
    return classNames({
      error: !_.isEmpty(this.props.errorMessage),
    });
  }

  handleInputChange(e) {
    const state = {
      [this.props.stateKey]: e.target.value,
    };
    this.props.onChange(state);
  }

  render() {
    return (
      <dl className={this._parentClassNames()}>
        <dt>
          <label
            htmlFor={this.props.labelName}
            className={this._labelClassNames()}
          >
            {this._constructLabelName()}
          </label>
        </dt>
        <dd>
          <input
            className={this._inputClassNames()}
            defaultValue={this.props.defaultValue}
            name={this.props.labelName}
            onChange={this.handleInputChange}
            placeholder={this.props.placeholder}
            type="text"
          />
        </dd>
      </dl>
    );
  }
};

GenericStringInput.propTypes = {
  inline: PropTypes.bool,
  size: PropTypes.oneOf(['small']),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  labelName: PropTypes.string.isRequired,
  stateKey: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
}

GenericStringInput.defaultProps = {
  onChange: function() {}
}
