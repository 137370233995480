import React from "react";
import PropTypes from "prop-types";

export default class CheckboxItem extends React.Component {
  render() {
    return (
      <li>
        <label>
          <input
            checked={this.props.checked}
            onChange={this.props.onChange}
            type="checkbox"
            value={this.props.value}
          />
          <span>{this.props.value}</span>
        </label>
      </li>
    );
  }
}

CheckboxItem.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

CheckboxItem.defaultProps = {
  checked: false,
  onChange: function() {},
}
