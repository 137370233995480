import React from "react";
import PropTypes from "prop-types";

import SelectPairItem from "./SelectPairItem";
import GenericSelect from "../../shared/GenericSelect";

export default class ContentItemOverridesSelectList extends React.Component {
  renderAddButton() {
    if (this.props.overrides.length < this.props.builtinOptions.length && this.props.customOptions.length > 0) {
      return (
        <li><a className="button add" href="#" onClick={this.props.onAdd}>add override</a></li>
      );
    }
  }

  renderBlankMessage() {
    if (this.props.customOptions.length === 0) {
      return (
        <div className='copy'>
          <p className='blank'>You haven't created any custom content items yet.</p>
        </div>
      );
    }
  }

  render() {
    return (
      <div id='content_item_overrides'>
        <h3 className="subhead margin-bottom-20">Content Item Overrides</h3>
          { this.renderBlankMessage() }
        <ul className="duplicating_list content_override_select_list">
          {
            this.props.overrides.map(function(override, index) {
              return(
                <SelectPairItem
                  key={index}
                  sourceValue={override.source}
                  sourceOptions={this.props.getSourceContentItems(override.source, "contentItemOverrides")}
                  onChangeSource={this.props.onChangeSource.bind(null, override.source, override.target, "source")}
                  onRemove={this.props.onRemove.bind(null, override.source, override.target)}>

                  <GenericSelect
                    className='kit_select_target'
                    selection={override.target}
                    onChange={this.props.onChangeTarget.bind(null, override.source, override.target, "target")}
                    options={this.props.customOptions}/>

                </SelectPairItem>
              );
            }, this)
          }
          { this.renderAddButton() }
        </ul>
      </div>
    );
  }
}

ContentItemOverridesSelectList.propTypes = {
  overrides: PropTypes.array,
  builtinOptions: PropTypes.array,
  customOptions: PropTypes.array,
  onChangeSource: PropTypes.func,
  onChangeTarget: PropTypes.func,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  getSourceContentItems: PropTypes.func.isRequired
}

ContentItemOverridesSelectList.defaultProps = {
  overrides: [],
  builtinOptions: [],
  customOptions: [],
  onChangeSource: function() {},
  onChangeTarget: function() {},
  onAdd: function() {},
  onRemove: function() {}
}
