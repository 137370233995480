import React from "react";
import PropTypes from "prop-types";

import generateRows from "../../row_generator";

import ContentItemTableHead from "./ContentItemTableHead";
import VariationRow from "./VariationRow";

function scrollIsNearBottom() {
  const { clientHeight, scrollHeight, scrollTop } = document.body;
  const scrollableHeight = scrollHeight - clientHeight;
  const loadBuffer = 200;

  return scrollTop >= scrollableHeight - loadBuffer;
}

function rowsWouldDiffer(oldProps, newProps) {
  const [a, b] = [oldProps, newProps].map(props =>
    _.pick(props, ['activeVariables', 'collapseBlanks', 'filter', 'searchAndReplaceCopy'])
  );
  return !_.isEqual(a, b);
}

export default class ContentItemTable extends React.Component {
  constructor(props) {
    super(props);

    const scrollLimit = 20;

    this.defaultScrollLimit = scrollLimit;
    this.state = { scrollLimit };

    this.onBodyScroll = this.onBodyScroll.bind(this);
  }

  componentDidMount() {
    document.addEventListener('scroll', this.onBodyScroll);
  }

  onBodyScroll() {
    const { scrollLimit } = this.state;

    if (scrollIsNearBottom() && this.rowCount >= scrollLimit) {
      this.setState({
        scrollLimit: scrollLimit + this.defaultScrollLimit,
      });
    }
  }

  shouldComponentUpdate(newProps, { scrollLimit }) {
    const bool =
      rowsWouldDiffer(this.props, newProps) ||
      this.state.scrollLimit !== scrollLimit;
    return bool;
  }

  componentWillReceiveProps(newProps) {
    if (rowsWouldDiffer(this.props, newProps))
      this.setState({ scrollLimit: this.defaultScrollLimit });
  }

  renderBodyRows() {
    const {
      activeVariables,
      allVariables,
      collapseBlanks,
      filter,
      versionHash,
      searchAndReplaceCopy,
    } = this.props;

    const { scrollLimit } = this.state;

    const setVersion = variation => content => {
      versionHash.setVersion({ variation, content });
      warnOnUnload();
    };

    const toggleVersion = values => enabled => {
      versionHash.toggleVersionDelete(values, !enabled);
      warnOnUnload();
    };

    const variations = generateRows({
      activeVariables,
      allVariables,
      collapseBlanks,
      filter,
      fetchAndUpdateVersion: versionHash.fetchAndUpdateVersion,
      getVersion: versionHash.getVersion,
      limit: scrollLimit,
      searchAndReplaceCopy,
    });

    this.rowCount = variations.length;

    return variations.map(({ values, version, enabled }) =>
      <VariationRow
        {...{
          enabled,
          setVersion: setVersion(values),
          toggleVersion: toggleVersion(values),
          variation: values,
          version,
        }}
        key={versionHash.variationToKey(values)}
      />
    );
  }

  render() {
    const {
      activeVariables,
      allVariables,
      filter,
      onFilterChange,
    } = this.props;

    return (
      <div>
        <ContentItemTableHead
          {...{
            activeVariables,
            allVariables,
            filter,
            onFilterChange,
          }}
        />

        <table className="contentitems body">
          <tbody>
            {this.renderBodyRows()}
          </tbody>
        </table>
      </div>
    );
  }
}

ContentItemTable.propTypes = {
  activeVariables: PropTypes.array,
  allVariables: PropTypes.object.isRequired,
  collapseBlanks: PropTypes.bool.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  variations: PropTypes.array,
  versionHash: PropTypes.shape({
    getVersion: PropTypes.func.isRequired,
    setVersion: PropTypes.func.isRequired,
    toggleVersionDelete: PropTypes.func.isRequired,
  }).isRequired,
  searchAndReplaceCopy: PropTypes.object,
};

ContentItemTable.defaultProps = {
  activeVariables: ['Gift/No Gift', 'Number of Cards'],
  searchAndReplaceCopy: { query: '', replacement: '' },
};
