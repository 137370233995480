import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import GenericSelect from "../../shared/GenericSelect";

export default class KitSelect extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  _parentClassNames() {
    return classNames({
      inline: this.props.inline
    });
  }

  handleInputChange(e) {
    var state = {};
    state[this.props.stateKey] = e.target.value;
    this.props.onChange(state);
  }

  render() {
    return (
      <dl className={this._parentClassNames()}>
        <dt>
          <label htmlFor={this.props.labelName}>{this.props.labelName}</label>
        </dt>
        <dd>
          <GenericSelect value={this.props.defaultValue} name={this.props.labelName} options={this.props.options} onChange={this.handleInputChange} />
        </dd>
      </dl>
    );
  }
}

KitSelect.propTypes = {
  inline: PropTypes.bool,
  labelName: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func
}

KitSelect.defaultProps = {
  onChange: function() {}
}
