import React from "react";
import PropTypes from "prop-types";
import ContentItemSearchInput from "./ContentItemSearchInput";

export default class ContentItemReplaceCopyInput extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.props.onChange({ value: e.target.value });
  }

  render() {
    return (
      <dl>
        <dt>
          <label>Replace Copy</label>
        </dt>
        <dd>
          <textarea
            id="content_item_replace"
            name="content_item_replace"
            onChange={this.onChange}
            placeholder="Replace matched copy with ..."
          />
        </dd>
      </dl>
    );
  }
}

ContentItemReplaceCopyInput.propTypes = {
  onChange: PropTypes.func.isRequired
}
