import React from "react";
import PropTypes from "prop-types";

import SelectPairItem from "./SelectPairItem";
// move to shared
import CheckboxList from "./CheckboxList";

export default class KitConstantsSelectList extends React.Component {
  getTargetOptions(variableName) {
    return _.find(this.props.kitConstantOptions, { variableName: variableName })
      .values;
  }

  renderAddButton() {
    if (
      this.props.activeKitConstants.length <
      _.keys(this.props.kitConstantOptions).length
    ) {
      return (
        <li>
          <a className="button add" href="#" onClick={this.props.onAdd}>
            add kit constant
          </a>
        </li>
      );
    }
  }

  renderErrorMessage() {
    var errorMessage = this.props.errorMessage;
    var classes = ['error'];

    if (errorMessage) {
      classes.push('visible');
    }

    return (
      <span className={classes.join(' ')}>
        {errorMessage}
      </span>
    );
  }

  render() {
    return (
      <div id="kit_constants">
        <div className="flex-row flex-row-center">
          <h3 className="subhead flex-row-item">
            Kit Constants
          </h3>
          <div className="tooltip flex-row-item nudge-right" title="Kit Constants are the answer values in the variables users know will not change. For example, if you are in a 'new' kit, you will know there will not be any offers. So the Kit Constant for the 'Offer' variable is 'No Offer'. For kits where there is a text override, you will be manually overriding content item, which means you should create kit constants for most of the variables."></div>
        </div>
        <div className="errorMessage margin-bottom-20">
          {this.renderErrorMessage()}
        </div>
        <ul className="duplicating_list">
          {this.props.activeKitConstants.map(function(constant, index) {
            return (
              <SelectPairItem
                key={constant.variableName}
                sourceValue={constant.variableName}
                sourceOptions={this.props.getSourceConstants(
                  constant.variableName
                )}
                onChangeSource={this.props.onChangeSource.bind(
                  null,
                  constant.variableName
                )}
                onRemove={this.props.onRemove.bind(null, constant.variableName)}
              >

                <CheckboxList
                  onChange={this.props.onChangeTarget.bind(
                    null,
                    constant.variableName
                  )}
                  options={this.getTargetOptions(constant.variableName)}
                  selections={constant.valueNames}
                />

              </SelectPairItem>
            );
          }, this)}
          {this.renderAddButton()}
        </ul>
      </div>
    );
  }
}

KitConstantsSelectList.propTypes = {
  activeKitConstants: PropTypes.arrayOf(
    PropTypes.shape({
      valueNames: PropTypes.array.isRequired,
      variableName: PropTypes.string.isRequired,
    })
  ),
  errorMessage: PropTypes.string,
  getSourceConstants: PropTypes.func.isRequired,
  kitConstantOptions: PropTypes.array.isRequired,
  onAdd: PropTypes.func,
  onChangeSource: PropTypes.func,
  onChangeTarget: PropTypes.func,
  onRemove: PropTypes.func,
}

KitConstantsSelectList.defaultProps = {
  activeKitConstants: [],
  kitConstantOptions: [],
  onChangeSource: function() {},
  onChangeTarget: function() {},
  onRemove: function() {},
  onAdd: function() {},
}
