import React from "react";
import PropTypes from "prop-types";

export default class CollapseButton extends React.Component {
  render() {
    var actionName = this.props.collapseBlanks ? 'expand' : 'collapse';

    return (
      <a className="button" href="#" onClick={this.props.onClick}>
        {actionName + ' blank versions'}
      </a>
    );
  }
}

CollapseButton.propTypes = {
  collapseBlanks: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}
