import React from "react";
import PropTypes from "prop-types";

import SelectPairItem from "./SelectPairItem";

export default class TextOverridesList extends React.Component {
  renderAddButton() {
    if (this.props.overrides.length < this.props.options.length) {
      return (
        <li><a className="button add" href="#" onClick={this.props.onAdd}>add text override</a></li>
      );
    }
  }

  render() {
    return (
      <div id='text_overrides'>
        <h3 className="subhead margin-bottom-20">Text Overrides</h3>
        <ul className="duplicating_list">
          {
            this.props.overrides.map(function(override, index) {
              return (
                <SelectPairItem
                  key={index}
                  sourceValue={override.name}
                  sourceOptions={this.props.getSourceContentItems(override.name, "textOverrides")}
                  onChangeSource={this.props.onChangeSelect.bind(null, override.name)}
                  onRemove={this.props.onRemove.bind(null, override.name)}>
                  <textarea
                    className="autosize"
                    value={override.text}
                    onChange={this.props.onChangeText.bind(null, override.name)}
                    className='kit_input_target'
                    placeholder='Enter override text ...'
                  />
                </SelectPairItem>
              );
            }, this)
          }
          { this.renderAddButton() }
        </ul>
      </div>
    );
  }
}

TextOverridesList.propTypes = {
  overrides: PropTypes.array,
  options: PropTypes.array,
  onChangeSelect: PropTypes.func,
  onChangeText: PropTypes.func,
  getSourceContentItems: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  onAdd: PropTypes.func
}

TextOverridesList.defaultProps = {
    overrides: [],
    options: [],
    onChangeSelect: function() {},
    onChangeText: function() {},
    onRemove: function() {},
    onAdd: function() {}
}
